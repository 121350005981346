body {
    font-family: 'Josefin Sans';
    background-color: #ffe6e6;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;

}

::-webkit-scrollbar {
    width: 0px;
}

.right-arrow,
.left-arrow {
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF2625;
    font-size: 25px;
    border-radius: 4px;
    position: absolute;
    bottom: -20px;
    right: 80px;
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
}

.right-arrow {
    right: 140px;
}

.right-arrow:hover,
.left-arrow:hover {
    transform: scale(1.3, 1.3);
}

.react-horizontal-scrolling-menu--wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.detail-image {
    width: 729px;
    height: 742px;
}

.hero-banner-img {
    position: absolute;
    right: 40px;
    top: 0px;
    width: 700px;
    height: 900px;
    margin-top: -330px;

}

.exercise-card {
    width: 400px;
    height: 445px;
    background: #fff;
    border-top: 4px solid #FF2625;
    border-bottom-left-radius: 20px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 10px;
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
}

.exercise-card img {
    height: 326px;
}

.bodyPart-card {
    transform: scale(1, 1);
    transition: 0.3s all ease-in-out;
}

.exercise-card:hover,
.bodyPart-card:hover {
    transform: scale(1.1, 1.1);
}

.search-btn:hover {
    color: #FF2625 !important;
    border: 1px solid #FF2625 !important;
}

.exercise-video {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 387px;
    height: 381px;
    text-decoration: none;
}

@media screen and (max-width:1200px) {
    .detail-image {
        width: 300px;
        height: 300px;
    }


    .react-horizontal-scrolling-menu--scroll-container {
        width: 500px;
    }

    .left-arrow,
    .right-arrow {
        position: static !important;
    }

    .hero-banner-img {
        display: none;
    }

    .exercise-card {
        width: 320px;
    }

    .exercise-video {
        width: 320px;
        height: 300px;
    }

}

@media screen and (max-width:400px) {
    .exercise-card {
        width: 280px;
    }
}